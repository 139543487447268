/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Import all the files you need like this;
// import '../src/filename.js';

import 'bootstrap/dist/js/bootstrap';
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import Inputmask from "inputmask";

global.Rails = Rails;

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("@rails/actioncable")
require("channels")
require('@client-side-validations/client-side-validations')
require('@client-side-validations/simple-form/dist/simple-form.bootstrap4')

const application = Application.start()
const context = require.context("./controllers", true, /.js$/)
application.load(definitionsFromContext(context))

$(function () {
    $('[data-toggle="tooltip"]').tooltip();
    $('.alert').alert();
});
