import { BaseController } from "./base_controller.js"

export default class extends BaseController {
    static targets = ["applicationList"]

    _handleResponse(response){
	if(response.status == 200){
	    response.text().then(html =>{ this.applicationListTarget.innerHTML = html });
	}else{
	    response.text().then(html => { this.applicationListTarget.innerHTML = "Something went wrong!" });
	}
    }
}
