import { Controller } from 'stimulus'

export default class extends Controller {
  switchLanguage(event) {
    let language = event.target.dataset.language || 'en';
    document.cookie = `locale=${language}; path=/;`;
    if (this.selectedLanguage !== language) {
      window.location.reload();
    }
  }

  get selectedLanguage() {
    return this.data.get('selectedLanguage');
  }
}
