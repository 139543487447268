import { Controller } from "stimulus"

export class BaseController extends Controller {
    connect(){
	this.load();
    }

    load(){
	fetch(this.data.get('url'))
	    .then(response => {
		this._handleResponse(response);
	    })
    }
}
