import { BaseController } from "./base_controller.js"

export default class extends BaseController {
    static targets = ["loanList"]

    _handleResponse(response){
	if(response.status == 200){
	    response.text().then(html =>{
		this.loanListTarget.innerHTML = html
		$("table.loans tbody tr").click(function(e){
		    if(event.target.tagName.toLowerCase() === 'a') return;
		    var win = window.open($(this).data('action-url'), '_blank');
		    win.focus();
		});
	    });
	}else{
	    response.text().then(html => { this.loanListTarget.innerHTML = "Something went wrong!" });
	}
    }
}
