import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "customerLast4Ssn", "customerDob" ]

    connect() {
        Inputmask({"mask": "99/99/9999", placeholder: "MM/DD/YYYY"}).mask(this.customerDobTarget);
        Inputmask({"mask": "9999", placeholder: "", removeMaskOnSubmit: true}).mask(this.customerLast4SsnTarget);
    }
}
